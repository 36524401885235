import React, { Component } from "react";
import Position from './Position'

class Company extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <div>
                {this.props.data &&
                    <div className="card">
                        <div className="card-body">
                        <h5 className="card-title">{this.props.data.company} <span className="font-italic">({this.props.data.startDate} - {this.props.data.endDate})</span></h5>
                        <div className="card-test">
                        {this.props.data.positions && this.props.data.positions.length > 0 && 
                            <ul className="list-unstyled ml-2">
                            {    
                                    this.props.data.positions.map((x, index) =>
                                        <li key={index}><Position data={x} /></li>
                                    )
                            }   
                            </ul>
                        }
                    </div>
                    </div>
                    </div>
                }
            </div>
        )
    }

}
export default Company;