import React, { Component, Fragment } from "react";
import styled from 'styled-components'

const ProgressBar = styled.div`
    width: ${props => props.percent ? props.percent + '%' : '0%'};
`;

class Tech extends Component {
    constructor(props) {
        super(props)
    }

    getSubclass(value) {
        let className = ''
        if (value) {
            if (value <= 25)
                className = 'bg-danger';
            else if (value <= 50)
                className = 'bg-warning';
            else if (value <= 75)
                className = 'bg-info';
            else if (value <= 100)
                className = 'bg-success';
        }
        return className
    }
    componentDidMount() { }

    render() {
        return (
            <div>
                {this.props.data &&
                    <div className="mb-3">
                        <h5>{this.props.data.name}</h5>
                        <div className="progress">
                            <ProgressBar percent={this.props.data.percent} className={`progress-bar progress-bar-striped ${this.getSubclass(this.props.data.percent)}`} />
                        </div>
                        {this.props.data.description &&
                            <p>{this.props.data.description}</p>
                        }
                    </div>
                }
                <div>
                    {this.props.data.subTechnology &&
                        <ul className="list-unstyled ml-3">
                            {this.props.data.subTechnology
                                .sort(({ percent: a }, { percent: b }) => b - a)
                                .map((x, index) =>
                                    <li key={index}><Tech data={x} /></li>
                                )}

                        </ul>
                    }
                </div>
            </div>

        )
    }
}
export default Tech;