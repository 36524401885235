import React, { Component, Fragment } from "react";
import firebase from "./firestore";
import './App.css';

import Welcome from "./pages/Welcome";
import WorkExperience from "./pages/WorkExperience";
import Technology from "./pages/Technology";
import Language from "./pages/Language";
// import Education from "./pages/Education";
// import { Contact } from "./pages/Contact";

const db = firebase.firestore();
const doc = db.collection('me').doc('johnQuinones');

class App extends React.Component {
  constructor(props){
    super(props);
     this.state = { }
  }

componentDidMount(){
  doc.get().then((doc) => {
    if (doc.exists) {
      //console.log(doc);
      //return doc.data()
      this.setState({
        data: doc.data()
      })
	  //console.log(this.state.data);
  }
});



}

getYear(){
  let d = new Date();
  return d.getFullYear();
}

render(){
  return (
    <div className="App">
      {this.state.data &&
        <Fragment>
          <header className="header"></header>
          <Welcome data={this.state.data} />
          {/* <Education data={this.state.data.education} /> */}
          <WorkExperience data={this.state.data.experience} />
          <Language data={this.state.data.language} />
          <Technology data={this.state.data.technology} />
          {/* <Contact /> */}
          <footer className="footer container">
            <div className="row">
              <div className="col">
                &copy; {this.getYear()} {this.state.data.firstName} {this.state.data.lastName}
              </div>
            </div>
          </footer>
        </Fragment>
      }

    </div>
  );
}
}

export default App;
