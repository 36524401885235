import React, { Component, Fragment } from "react";
import Tech from "../components/Tech";

class Technology extends Component {
    constructor(props) {
        super(props);

    }

    componentDidMount() {

    }

    render() {
        return (
            <section className="mb-5">
                <div id="technology" className="container">
                    <div className="row">
                        <div className="col">
                            <h3>Technologies & Platforms</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        {this.props.data &&
                            this.props.data
                            .sort(({percent: a},{percent: b}) => b - a)
                            .map((x,index) =>
                                <Fragment key={index}>
                                    <Tech data={x} />
                                </Fragment>
                            )}
                            </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Technology;