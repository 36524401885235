import React, { Component, Fragment } from "react";

class Position extends Component{
    constructor(props){
        super(props)
    }

    componentDidMount(){

    }

    render(){
        return(
            <div>
                <h5>{this.props.data.title}</h5>
                <div>{this.props.data.startDate} - {this.props.data.endDate}</div>
                {this.props.data.responsibilities &&
                <div>
                    <h5>Responsibilities</h5>
                    <dl className="">
                    
                        {this.props.data.responsibilities.map((x,index) =>
                            <Fragment key={index}>
                                <dt>{x.title}</dt>
                                <dd>{x.description}</dd>
                            </Fragment>
                        )}

                    
                    </dl>
                   
                </div>
                }      
            </div>
        )
    }

}
export default Position;