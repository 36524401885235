import firebase from 'firebase';
//import firebase from 'firebase/app';
const config = {
    apiKey: "AIzaSyA9svw5gvf2fNdKyZFf24CrU4KubpYcKnU",
    authDomain: "johnquinones-me.firebaseapp.com",
    projectId: "johnquinones-me",
    storageBucket: "johnquinones-me.appspot.com",
    messagingSenderId: "988987597763",
    appId: "1:988987597763:web:e1103f37c8f8d59eea5376"
};
firebase.initializeApp(config);
export default firebase;