import React, { Component } from "react";
import Avatar from "../components/Avatar";

class Welcome extends Component {
    constructor(props) {
        super(props);


    }
    componentDidMount() {
    }

    fullName(){

    }

    fullNameTitle(){
        return this.props.data.firstName + ' ' + this.props.data.lastName + ' ' + this.props.data.title;
    }

    render() {
        return (

            <div className="welcome jumbotron">
                <div className="container-fluid">
                    <div className="row">
                        <div className="sm-col-12 md-col-2">
                            {/* <img className="rounded-circle" src={this.props.data.avatar} /> */}
                            <Avatar altTag={this.fullNameTitle()} data={this.props.data.avatar} />
                        </div>
                        <div className="sm-col-12 md-col-10">
                            <h1>{this.props.data.firstName + ' ' + this.props.data.lastName}</h1>
                            <h2>{this.props.data.title}</h2>
                            <ul className="list-inline">
                                <li className="list-inline-item">{this.props.data.location}</li>
                                <li className="list-inline-item">|</li>
                                <li className="list-inline-item">{this.props.data.phone}</li>
                                <li className="list-inline-item">|</li>
                                <li className="list-inline-item">{this.props.data.email}</li>
                            </ul>
                            <p>{this.props.data.about}</p>
                        </div>
                    </div>
                </div>



            </div>
        )
    }
}

export default Welcome;