import React, { Component } from "react";
import Company from "../components/Company";

class WorkExperience extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

    }

    render() {
        return (
            <section className="mb-5">
                <div id="work-experience" className="container">
                    <div className="row">
                        <div className="col">
                            <h3>Work Experience</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="">
                                {this.props.data &&
                                    this.props.data.map((c, index) =>
                                        <div className="mb-2" key={index}><Company data={c} /></div>
                                    )
                                    }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }



}
export default WorkExperience;