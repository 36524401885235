import React, { Component } from "react";
import firebase from "../firestore";

class Avatar extends Component{
    constructor(props){
        super(props)
    }

    getImage(){
        // Create a reference with an initial file path and name
        var storage = firebase.storage();
        //var pathReference = storage.ref('images/stars.jpg');

        // Create a reference from a Google Cloud Storage URI
        //var gsReference = storage.refFromURL('gs://bucket/images/stars.jpg');

        // Create a reference from an HTTPS URL
        // Note that in the URL, characters are URL escaped!
        //var httpsReference = storage.refFromURL('https://firebasestorage.googleapis.com/b/bucket/o/images%20stars.jpg');

        storage.refFromURL(this.props.data).getDownloadURL().then(function(url) {
                //alert(url);
                document.querySelector('img').src = url;
                //return url;
   
            }).catch(function(error) {
   
            });;
    }


render(){
    return(
        <div className="avatar-container">
            <img alt={this.props.altTag} className="avatar img-fluid" src={this.props.data && this.getImage()} />
        </div>
    )
}
}
export default Avatar;